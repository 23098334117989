import { Box, Grid, Typography } from "components"
import { AppColor } from "theme/app-color"
import styled from "styled-components"
import { toCurrency } from "utils/helper"
import { Label } from "../claim-detail/form-field/Label"
import { isEmpty } from "lodash"

const DetailContainer = styled(Box)``

const BudgetCard = styled(Box)`
  padding: 8px;
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 4px;
  background-color: ${AppColor["White / White"]};
`

const LimitDataComponent = (props: any) => {
  const { limitBudget, condition } = props
  const validate = condition ? condition : true
  return (
    <Typography variant="body1" color="Text/Gray Success" style={{ lineHeight: "24px", marginLeft: "4px" }}>
      /
      {!isNaN(limitBudget) && validate
        ? toCurrency(limitBudget, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : "-"}
    </Typography>
  )
}

const RemainDataComponent = (props: any) => {
  const { remainingBudget, condition } = props
  const validate = condition ? condition : true
  const budgetValue = remainingBudget <= 0 ? 0 : remainingBudget
  return (
    <Typography variant="h3" color="Primary/Primary Text">
      {!isNaN(remainingBudget) && validate
        ? toCurrency(budgetValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : "-"}
    </Typography>
  )
}

const getYear = (year: any) => {
  return year ? year : "N/A"
}

export const BudgetComponent = (props: any) => {
  const { budgetDetail, budgetValues, year, values, yearlyBudget, type } = props

  const remainingBudget = type === "dental_fee" ? yearlyBudget.dentalBudget : yearlyBudget.budget
  const limitBudget = type === "dental_fee" ? yearlyBudget.companyDentalBudget : yearlyBudget.companyBudget
  return (
    <>
      {!isEmpty(budgetDetail) && budgetValues && (
        <Grid container spacing={2} mb={1} mt={8}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Label title={"คะแนนคงเหลือ"} icon="payments" iconColor={AppColor["Primary/Primary Text"]} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <BudgetCard>
              <Box display="flex" flexDirection="column" padding="8px">
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" color="Text/Gray Preview">
                    คะแนนคงเหลือปี
                  </Typography>
                  <Typography variant="h3" color="Primary/Primary Text">
                    {getYear(year)}
                  </Typography>
                </Box>
                {budgetDetail && (
                  <DetailContainer>
                    {budgetDetail.map((detail: any) => {
                      return (
                        <Box display="flex" pt={2} justifyContent="space-between" key={`${detail.label}-3`}>
                          <Typography variant="body1" color="Text/Gray Preview">
                            {detail.label}
                          </Typography>
                          <Box display="flex">
                            {detail.field ? (
                              <>
                                {detail.maximum !== "auto" ? (
                                  <>
                                    <RemainDataComponent remainingBudget={remainingBudget} />
                                    <LimitDataComponent limitBudget={limitBudget} />
                                  </>
                                ) : (
                                  <>
                                    <RemainDataComponent
                                      remainingBudget={values[detail.maximumFiled] - budgetValues[detail.field]}
                                      condition={values[detail.maximumFiled] && values}
                                    />
                                    <LimitDataComponent
                                      limitBudget={values[detail.maximumFiled]}
                                      condition={values[detail.maximumFiled] && values}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <Typography variant="h3" color="Primary/Primary Text">
                                {detail.value ? detail.value : "N/A"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )
                    })}
                  </DetailContainer>
                )}
              </Box>
            </BudgetCard>
          </Grid>
        </Grid>
      )}
    </>
  )
}
