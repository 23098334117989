import { Box, Typography } from "components"
import { compose, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import paths from "routes/paths"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const ImageNameCox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${AppColor["Text/Background2"]};
`
const QuantityBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  min-width: 60px;
  margin-left: 8px;
`

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ConsiderationDetailComponent = (props: any) => (
  <PageContent title="รายละเอียดการพิจารณา" titleCentered onBack={props.handleClickBack} type="primary">
    {props.images.map((image: any, index: number) => (
      <>
        <ImageNameCox>
          <Box minWidth="275px">
            <Typography variant="body1" color="Text/Primary" noWrap>
              {image.fileName}
            </Typography>
          </Box>
          <QuantityBox>
            <Typography variant="body1" color="Text/Primary">
              {`${index + 1} จาก ${props.images.length}`}
            </Typography>
          </QuantityBox>
        </ImageNameCox>
        <ImageBox>
          <img src={image.url} alt="" style={{ maxWidth: "100%", height: "auto" }} />
        </ImageBox>
      </>
    ))}
  </PageContent>
)

const enhancer = compose(
  withStores((stores: any) => ({
    settingInfo: stores.settingStore.setting,
  })),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useUrlParam } = hooks
    const { settingInfo } = props
    const history = useHistory()
    const urlParams = useUrlParam()

    const handleClickBack = useCallback(() => {
      if (!isEmpty(urlParams)) {
        paths.claimDetailPath(urlParams).push()
        return
      }
      history.goBack()
    }, [history, urlParams])

    return { handleClickBack, images: settingInfo?.images || [] }
  }),
)

export default enhancer(ConsiderationDetailComponent)
