/* eslint-disable no-undef */

export const env = getEnvConfig()

export const theme = getThemeConfig()

const opd = getOpdFunctionsConfig()
const opd_medical = getOpdMedicalFunctionsConfig()
const dental_fee = getDentalFeeFunctionConfig()
const pass_away_sponsor = getPassAwaySponsorFunctoinConfig()
const pass_away_wreath = getPassAwayWreathFunctoinConfig()
const child_support = getChildSupportFunctionsConfig()

const ipd_medical = getIpdMedicalFunctionsConfig()
const pass_away_support = getPassAwaySupportFunctionsConfig()
const child_education = getChildEducationFunctionsConfig()
const request = getRequestNameFunctionConfig()
export const functions = {
  autoCal: {
    ...opd.autoCal,
    ...opd_medical.autoCal,
    ...dental_fee.autoCal,
    ...pass_away_sponsor.autoCal,
    ...pass_away_wreath.autoCal,
    ...ipd_medical.autoCal,
    ...pass_away_support.autoCal,
    ...child_support.autoCal,
    ...child_education.autoCal,
  },
  validate: {
    ...opd.validate,
    ...opd_medical.validate,
    ...dental_fee.validate,
    ...pass_away_sponsor.validate,
    ...pass_away_wreath.validate,
    ...ipd_medical.validate,
    ...child_support.validate,
    ...request.validate,
  },
  calculate: {
    ...request.calculate,
  },
}

const configs = {
  env,
  theme,
  functions,
}

export default configs
