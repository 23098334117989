import { ApolloError } from "@apollo/client"
import { Box, Button, Field, Form, TextField, Typography } from "components"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { gql, paths } from "utils/helper"
import * as yup from "yup"
import { call } from "common/helper"

import AuthModal from "./AuthModal"

const ResetButton = styled(Button)`
  margin-top: 425px;
  border-radius: 12px;
  &:focus {
    background-color: ${AppColor["Dark Blue/Primary Text"]};
  }
  &:active {
    background-color: ${AppColor["Dark Blue/Primary Text"]};
  }
`

const ListContainer = styled(Box)`
  padding: 24px;
`

const ResetPasswordComponent = (props: any) => (
  <>
    <PageContent title="ลืมรหัสผ่าน" titleCentered onBack={props.handleClickBack}>
      <ListContainer>
        <Box>
          <Typography variant="h2" color="Primary/Primary Text">
            กรอกที่อยู่อีเมล
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h4" color="Text/Gray Preview">
            ระบบจะส่งลิงก์สำหรับตั้งรหัสผ่านใหม่ไปทางอีเมล
          </Typography>
        </Box>

        <Form>
          <Box display="flex" mt={4}>
            <Typography variant="body1" color="Text/Black2">
              อีเมล
            </Typography>
          </Box>
          <Field component={TextField} name="email" type="text" placeholder="อีเมล" fullWidth mt={2} />
          <ResetButton variant="contained" type="submit" fullWidth>
            รีเซตรหัสผ่าน
          </ResetButton>
        </Form>
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant="caption" color="Text/Gray Success" style={{ fontSize: "12px" }}>
            App version 1.0
          </Typography>
        </Box>
      </ListContainer>
      <AuthModal
        isOpen={props.isModalOpen}
        isSuccess={true}
        title="ส่งอีเมลสำเร็จ"
        description="โปรดตรวจสอบอีเมลของคุณเพื่อตั้งรหัสผ่านใหม่"
      />
    </PageContent>
  </>
)

export const API = {
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!, $preferredUserName: String!) {
      resetPassword(input: { email: $email, preferredUserName: $preferredUserName }) {
        id
        userToken
        userType
      }
    }
  `,
  VALIDATE_EMAIL: gql`
    query VALIDATE_EMAIL($email: String!) {
      validateEmail(input: { email: $email })
    }
  `,
}

const validationSchema = yup.object().shape({
  email: yup.string().email("รูปแบบอีเมลไม่ถูกต้อง").required("อีเมลต้องไม่เว้นว่าง"),
})

const enhancer = compose(
  withFormik({ validationSchema }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useState, useCallback, useEffect, useLazyQuery } = hooks
    const { setFieldError, values, isValid } = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const history = useHistory()

    const [resetPassword] = useMutation(API.RESET_PASSWORD, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        setIsModalOpen(true)
      },
      onError: (data: ApolloError) => {
        setFieldError("email", data.message)
      },
    })

    const [validateEmail] = useLazyQuery(API.VALIDATE_EMAIL, {
      fetchPolicy: "network-only",
    })

    const handleClickBack = useCallback(() => {
      paths.signInPath().push()
    }, [history])

    useEffect(() => {
      call(async () => {
        if (isValid && values.email) {
          const { data } = await validateEmail({ variables: { email: values.email } })
          if (!data.validateEmail) {
            setFieldError("email", "ไม่พบอีเมลนี้ในระบบ")
          }
        }
      })
    }, [values.email, isValid])

    useHandleSubmit(async (value: any) => {
      const { email } = value
      if (!isEmpty(email)) {
        resetPassword({
          variables: {
            email: email,
            preferredUserName: email,
          },
        })
      }
    }, [])

    return { handleClickBack, isModalOpen }
  }),
)

export default enhancer(ResetPasswordComponent)
