import { gql } from "@apollo/client"
import { compose, withHooks } from "enhancers"
import { useCallback, useEffect, useMemo } from "react"
import paths from "routes/paths"

import { useMsal } from "@azure/msal-react"
import { getToken, removeToken, setToken, removeThemeName } from "api"
import { Box, Icon, Modal, Redirect, Route, Switch } from "components"
import { AES, enc, mode } from "crypto-js"
import { msalInstance } from "index"
import { isEmpty } from "lodash"
import Page404 from "pages/auth/Page404"
import Page498 from "pages/auth/Page498"
import Page500 from "pages/auth/Page500"
import SignIn from "pages/auth/SignIn"
import ClaimDetailPage from "pages/main/claim-detail/index"
import ClaimRequestPage from "pages/main/claim-requests"
import ApprovalPage from "pages/main/claim-requests/detail"
import DashboardComponent from "pages/main/dashboard"
import dashboardDetail from "pages/main/dashboard/dashboardDetail"
import HistoryComponent from "pages/main/e-claim-history"
import HistoryDetailComponent from "pages/main/e-claim-history/detail"
import eClaimLanding from "pages/main/e-claim-landing"
import ListPageComponent from "pages/main/e-claim-list/index"
import userStore from "stores/userStore"
import Loader from "components/Loader"
import ResetPassword from "pages/auth/ResetPassword"
import SetupPassword from "pages/auth/SetupPassword"
import { env } from "configs"
import EditPage from "pages/main/claim-requests/edit"
import { AppColor } from "theme/app-color"
import Typography from "components/common/Typography"
import ConsiderationDetailComponent from "pages/main/consideration-detail"
import settingStore from "stores/settingStore"

type RedirectToUrl = {
  pathname: string
  search?: string
}

const MainPages = ({ redirectTo }: { redirectTo?: RedirectToUrl }) => (
  <Switch>
    <Route path={paths.landingPath()} exact component={eClaimLanding} />
    <Route path={paths.claimDetailPath()} exact component={ClaimDetailPage} />
    <Route path={paths.listPath()} exact component={ListPageComponent} />
    <Route path={paths.dashboardPath()} exact component={DashboardComponent} />
    <Route path={paths.dashboardDetailPath(":id")} exact component={dashboardDetail} />
    <Route path={paths.historyPath()} exact component={HistoryComponent} />
    <Route path={paths.historyDetailPath(":id")} exact component={HistoryDetailComponent} />
    <Route path={paths.claimRequestsPath()} exact component={ClaimRequestPage} />
    <Route path={paths.approvalRequestPath(":id")} exact component={ApprovalPage} />
    <Route path={paths.editDraftRequestPath(":id")} exact component={EditPage} />
    <Route path={paths.considerationDetailPath()} exact component={ConsiderationDetailComponent} />

    {redirectTo && <Redirect to={{ pathname: redirectTo.pathname, search: redirectTo.search }} />}
    <Redirect to={paths.landingPath()} />
  </Switch>
)

const GuestPages = () => (
  <Switch>
    <Route path={paths.page404Path()} exact component={Page404} />

    <Route path={paths.page500Path()} exact component={Page500} />
    <Route path={paths.page498Path()} exact component={Page498} />

    <Route path={paths.signInPath()} exact component={SignIn} />
    <Route path={paths.resetPasswordPath()} exact component={ResetPassword} />
    <Route path={paths.setupPasswordPath(":id")} exact component={SetupPassword} />

    {
      <Redirect
        to={{
          pathname: paths.signInPath(),
          search: `redirect_to=${encodeURIComponent(
            // eslint-disable-next-line no-restricted-globals
            location.protocol + location.host + location.pathname + location.search,
          )}`,
          // eslint-disable-next-line no-restricted-globals
          state: { referer: location.pathname + location.search },
        }}
      />
    }

    <Redirect to={paths.signInPath()} />
  </Switch>
)

interface RoutesProps {
  initialized: boolean
  isAuthorized: boolean
  loading: boolean
  redirectTo?: RedirectToUrl
}

const Routes = (props: RoutesProps) => {
  const { initialized, isAuthorized, loading } = props
  if (loading) return <Loader />
  else if (!initialized || !isAuthorized) return <GuestPages />
  else return <MainPages redirectTo={props.redirectTo} />
}

export const API = {
  SIGN_IN_VIA_AZURE_AD: gql`
    mutation SIGN_IN_VIA_AZURE_AD($email: String!, $authToken: String!, $preferredUserName: String!) {
      signInViaAzureAd(input: { email: $email, authToken: $authToken, preferredUserName: $preferredUserName }) {
        id
        officialMail
        user {
          userToken
          userType
          id
        }
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
          title
        }
      }
    }
  `,
  DEBUG_WITH_MS_TEAM: gql`
    mutation DEBUG_WITH_MS_TEAM($tag: String!, $messages: JSON!) {
      debugWithMsTeam(input: { tag: $tag, messages: $messages })
    }
  `,
  GET_SETTING: gql`
    query GET_SETTING {
      setting {
        id
        showDetailOn
        startClaimRequestEnrollment
        endClaimRequestEnrollment
        notifyBeforeStartAt
        images {
          url
          fileName
        }
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState, useUrlParam, useMutation, useLazyQuery } = hooks
    const { token: encryptedToken, withPortalToken } = useUrlParam()

    const [isReady, setIsReady] = useState(false)
    const [isMsalLoading, setIsMsalLoading] = useState(true)

    // Manual declare type of redirectTo and setRedirectTo because of useState from hooks doesn't support type
    let redirectTo: RedirectToUrl | null
    let setRedirectTo: (value: RedirectToUrl | null) => void
    ;[redirectTo, setRedirectTo] = useState(null)

    const { accounts } = useMsal()

    const [fetchSetting] = useLazyQuery(API.GET_SETTING, {
      onCompleted: (data: any) => {
        settingStore.setSetting(data.setting)
      },
      onError: (error: any) => {
        console.log("GET SETTING USER ERROR : ", error)
      },
    })

    const errorModal = useCallback((message) => {
      // show modal on error when signin via azure
      const error = message.replace("ApolloError: ", "")
      // @ts-ignore
      return Modal.alert({
        className: "ErrorModal",
        title: "",
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="Warning" color={AppColor["Error/Error Text"]} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                เกิดข้อผิดพลาด
              </Typography>
            </Box>
            <Box mt="16px" pb="16px">
              <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                {error}
              </Typography>
            </Box>
          </div>
        ),
        onOk: () => {
          sessionStorage.clear()
          paths.signInPath().push()
          window.location.reload()
          // @ts-ignore
          Modal.close()
        },
        okButtonVariant: "contained",
      })
    }, [])

    const [signInWithAzureAd] = useMutation(API.SIGN_IN_VIA_AZURE_AD, {
      onCompleted: (data: any) => {
        const { signInViaAzureAd } = data
        console.log("LOGIN WITH AZURE AD: ", signInViaAzureAd)

        if (signInViaAzureAd.user.userToken) {
          setToken(signInViaAzureAd.user.userToken)
          // window.location.reload()
          fetchCurrentUser()
        }
        setIsMsalLoading(false)
      },
      onError: (error: any) => {
        console.log("LOGIN WITH AZURE AD ERROR : ", error)
        msalInstance.setActiveAccount(null)
        removeToken()
        setIsMsalLoading(false)
        errorModal(error.message)
      },
    })

    const [fetchCurrentUser, { loading, error: currentUserError, data: currentUser }] = useLazyQuery(
      API.GET_CURRENT_USER,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          userStore.setCurrentUser(data.getCurrentUser)
          let title = JSON.parse(localStorage.getItem("themeName") as string)

          // eslint-disable-next-line no-restricted-globals
          const queryParams = new URLSearchParams(location.search)
          const currentRedirectTo = queryParams.get("redirect_to")

          if (title !== data.getCurrentUser?.employee?.title) {
            const { employee } = data.getCurrentUser || {}
            const { title } = employee || {}

            localStorage.setItem("themeName", JSON.stringify(title))

            // alert(`After sign in, redirect to home : ${decodeURIComponent(currentRedirectTo || "/home")}`)
            if (currentRedirectTo) {
              window.location.href = decodeURIComponent(currentRedirectTo)
            }

            window.location.href = paths.homePath()
          } else if (currentRedirectTo) {
            const redirectToUrl = new URL(decodeURIComponent(currentRedirectTo))
            if (redirectToUrl.pathname === "/") {
              window.location.href = paths.homePath()
            }
            setRedirectTo({ pathname: redirectToUrl.pathname, search: redirectToUrl.search })
          }

          setIsReady(true)
        },
        onError: (error: any) => {
          console.log("GET CURRENT USER ERROR : ", error)
          userStore.setCurrentUser(null)
          setIsReady(false)
          removeToken()
          removeThemeName()
          if (!error.message.includes("Unauthorized!")) errorModal(error.message)
        },
      },
    )

    const [debugWithMsTeam] = useMutation(API.DEBUG_WITH_MS_TEAM)

    const pageLoading = useMemo(() => loading || isMsalLoading, [isMsalLoading, loading])

    const initialized = !pageLoading && isReady
    const isAuthorized = useMemo(() => {
      return !!(!currentUserError && currentUser)
    }, [currentUserError, currentUser])

    useEffect(() => {
      if (withPortalToken) {
        setIsMsalLoading(false)
        removeToken()
        removeThemeName()
        setToken(withPortalToken)
      } else if (encryptedToken) {
        setIsMsalLoading(false)
        localStorage.setItem("Encrypted_token", encryptedToken)
        removeToken()
        removeThemeName()
        const secret = env.REACT_APP_LOGIN_SECRET

        let secSpec = enc.Utf8.parse(secret)
        let ivSpec = enc.Utf8.parse("")

        const decrypted = AES.decrypt(encryptedToken, secSpec, {
          iv: ivSpec,
          mode: mode.CBC,
        })
        const email = decrypted.toString(enc.Utf8)
        if (!isEmpty(email)) {
          signInWithAzureAd({
            variables: {
              email: email,
              authToken: "",
              preferredUserName: email,
            },
          })
        }
      } else if (accounts) {
        msalInstance
          .handleRedirectPromise()
          .then(async () => {
            const account = accounts[0]
            const currentToken = getToken()
            if (account && !currentToken) {
              if (account) debugWithMsTeam({ variables: { tag: "MSAL", messages: account } })
              await signInWithAzureAd({
                variables: {
                  email: account?.idTokenClaims?.email,
                  authToken: "",
                  preferredUserName: account.idTokenClaims?.preferred_username,
                },
              })
            } else {
              setIsMsalLoading(false)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const currentToken = getToken()

      if (currentToken && !currentUser) {
        fetchCurrentUser()
      }
    }, [withPortalToken, encryptedToken, accounts, debugWithMsTeam, signInWithAzureAd, currentUser, fetchCurrentUser])

    useEffect(() => {
      if (isAuthorized) fetchSetting()
    }, [fetchSetting, isAuthorized])

    return {
      initialized,
      isAuthorized,
      loading: pageLoading,
      redirectTo,
    }
  }),
)

export default enhancer(Routes)
