import * as MaterialIcon from "@material-ui/icons"
import { ReactComponent as AdjustCircle } from "assets/icon/adjust.svg"
import { ReactComponent as AttachEmail } from "assets/icon/attach_email.svg"
import { ReactComponent as Crat } from "assets/icon/cart-icon.svg"
import { ReactComponent as NotFoundIcon } from "assets/icon/error_outline.svg"
import { ReactComponent as ExportFile } from "assets/icon/export_file.svg"
import { ReactComponent as Home } from "assets/icon/home.svg"
import { ReactComponent as Payments } from "assets/icon/payments.svg"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { ReactComponent as Report } from "assets/icon/report.svg"
import { ReactComponent as StopCircle } from "assets/icon/stop_circle.svg"
import { ReactComponent as AddNotes } from "assets/icon/using/add_notes.svg"
import { ReactComponent as AirportShuttle } from "assets/icon/using/airport_shuttle.svg"
import { ReactComponent as RightArrowOutlined } from "assets/icon/using/arrow_right.svg"
import { ReactComponent as AutoStories } from "assets/icon/using/auto_stories.svg"
import { ReactComponent as BreastFeeding } from "assets/icon/using/breastfeeding.svg"
import { ReactComponent as ChildCare } from "assets/icon/using/child_care.svg"
import { ReactComponent as Computer } from "assets/icon/using/computer.svg"
import { ReactComponent as Dentistry } from "assets/icon/using/dentistry.svg"
import { ReactComponent as DeviceWareables } from "assets/icon/using/devices_wearables.svg"
import { ReactComponent as ElectricMeter } from "assets/icon/using/electric_meter.svg"
import { ReactComponent as Exercise } from "assets/icon/using/exercise.svg"
import { ReactComponent as EyeGlasses } from "assets/icon/using/eyeglasses.svg"
import { ReactComponent as Favorite } from "assets/icon/using/favorite.svg"
import { ReactComponent as Fight } from "assets/icon/using/flight.svg"
import { ReactComponent as Healing } from "assets/icon/using/healing.svg"
import { ReactComponent as Hotel } from "assets/icon/using/hotel.svg"
import { ReactComponent as localTaxi } from "assets/icon/using/local_taxi.svg"
import { ReactComponent as MedicalServices } from "assets/icon/using/medical_services.svg"
import { ReactComponent as SadTear } from "assets/icon/using/sad-tear.svg"
import { ReactComponent as Schedule } from "assets/icon/using/schedule.svg"
import { ReactComponent as School } from "assets/icon/using/school.svg"
import { ReactComponent as SentimentDissatisfied } from "assets/icon/using/sentiment_dissatisfied.svg"
import { ReactComponent as SportsTennis } from "assets/icon/using/sports_tennis.svg"
import { ReactComponent as Vaccines } from "assets/icon/using/vaccines.svg"
import { ReactComponent as Wreath } from "assets/icon/using/wreath.svg"
import React from "react"
import { CustomMuiIconProps } from "./MuiIcon"

type MuiIconCollections = keyof typeof MaterialIcon
export type IconName = keyof typeof SvgIcon | MuiIconCollections

interface IconProps extends Omit<CustomMuiIconProps, "name"> {
  name: IconName
}

export const SvgIcon = {
  addNotes: AddNotes,
  adjust: AdjustCircle,
  airportShuttle: AirportShuttle,
  arrowRight: RightArrowOutlined,
  attach_email: AttachEmail,
  auto_stories: AutoStories,
  breastFeeding: BreastFeeding,
  childCare: ChildCare,
  computer: Computer,
  crat: Crat,
  dentistry: Dentistry,
  devices_wearables: DeviceWareables,
  electric_meter: ElectricMeter,
  exercise: Exercise,
  export: ExportFile,
  eyeglasses: EyeGlasses,
  favorite: Favorite,
  fight: Fight,
  healing: Healing,
  home: Home,
  hotel: Hotel,
  localTaxi: localTaxi,
  medical_services: MedicalServices,
  payments: Payments,
  point: Point,
  report: Report,
  sadTear: SadTear,
  schedule: Schedule,
  school: School,
  sentimentDissatisfied: SentimentDissatisfied,
  sports_tennis: SportsTennis,
  stop_circle: StopCircle,
  vaccines: Vaccines,
  wreath: Wreath,
}

const IconComponent = ({ name, color, width = "20px", height = "20px", style, ...rest }: IconProps) => {
  if (name) {
    if (name in SvgIcon) {
      const Svg = SvgIcon[name as keyof typeof SvgIcon]
      return <Svg fill={color} width={width} height={height} {...rest} />
    }
    if (MaterialIcon[name as MuiIconCollections])
      return React.createElement(MaterialIcon[name as MuiIconCollections], {
        ...rest,
        htmlColor: color,
        style: {
          ...style,
          width,
          height,
        },
      })
  }

  return <NotFoundIcon />
}

export default IconComponent
