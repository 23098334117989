import { gql } from "@apollo/client"
import { Box, Button, Icon, Modal, MuiIcon, Notification, Typography } from "components"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts/PageContent"
import { every, isEmpty } from "lodash"
import paths from "routes/paths"
import styled from "styled-components"
import FilterClaimRequests from "./FilterModal"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "components/Loader"
import { AppColor } from "theme/app-color"
import RejectModal from "./detail/reject-modal"
import { functions } from "configs"

const HistoryContainer = styled.div`
  margin-top: 16px;
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${AppColor["White / White"]};
  border-top: 1px solid ${AppColor["Text/Gray"]};
  position: fixed;
  bottom: 0;
  z-index: 1;
  max-width: 752px;
  width: calc(100% - 32px);
`

const PhantomContainer = styled("div")`
  display: block;
  padding: 20px;
  height: 72px;
  width: calc(100% - 32px);
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`
const RejectActionButton = styled(Button)`
  width: 100%;
  background-color: ${AppColor["White / White"]};
  &:focus {
    background-color: ${AppColor["White / White"]};
  }
  &:active {
    background-color: ${AppColor["White / White"]};
  }
  &:hover {
    background-color: ${AppColor["System/Error Light Hover"]};
  }
`
const ActionButton = styled(Button)`
  width: 100%;
`
const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

const Contents = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ClaimRequestsPageComponent = (props: any) => (
  <>
    <RejectModal
      title={`${props.claimRequestRange} รายการ`}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={null}
      rejectReasons={props.rejectReasons}
    />
    <PageContent
      title="รายการขอเบิก"
      type="secondary"
      titleCentered
      onBack={props.handleClickBack}
      showFilter
      hasFilter={props.hasFilter}
      openFilterModal={props.openFilterModal}
      filterCount={props.filterCount}
    >
      <Box padding="16px">
        <StatusTab
          selectedStatus={props.filteredStatus}
          onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
          hasCancel={false}
        />
        <HistoryContainer>
          {props.showNotFoundSearch ? (
            <Box>
              <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="h3" color="Gray/Secondary Text">
                  รายการค้นหา
                </Typography>
                <Typography variant="subtitle2" color="Gray/Primary Text">
                  (0 รายการ)
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                position="fixed"
                top="40%"
                left="10%"
                right="10%"
              >
                <Typography variant="h3" color="Gray/Secondary Text">
                  ไม่พบรายการที่ต้องการ
                </Typography>
                <Typography variant="h4" color="Text/Placeholder">
                  กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
                </Typography>
              </Box>
            </Box>
          ) : (
            <InfiniteScroll
              dataLength={props.claimRequestList.length ?? 0}
              next={props.fetchMoreData}
              hasMore={props.hasMore}
              loader={<Loader />}
            >
              {props.claimRequestList.map((history: any) => (
                <ClaimRequestHistoryItem
                  key={history.id}
                  id={history.id}
                  info={history.info}
                  department={history.employee.department}
                  workflowSeq={history.workflowSeq}
                  createdAt={history.createdAt}
                  referenceId={history.referenceId}
                  amount={props.requestAmount(history)}
                  onClick={(id: string) => props.handleClickHistoryItem(id)}
                  owner={history.employee}
                  createdBy={history.createdBy}
                  userRole={props.currentUser.role}
                  status={history.status}
                  filteredStatus={props.filteredStatus}
                />
              ))}
            </InfiniteScroll>
          )}
          {props.claimRequestList.length === 0 && !props.hasMore && !props.hasFilter && (
            <ContentContainer>
              <Contents>
                <Typography variant="h3" color="Text/Primary Text">
                  ไม่มีรายการขอเบิก
                </Typography>
                <Typography variant="body1" color="Text/Gray Preview" style={{ fontSize: "16px", marginTop: "17px" }}>
                  ไม่พบข้อมูลการขอเบิกในสถานะนี้
                </Typography>
              </Contents>
            </ContentContainer>
          )}
        </HistoryContainer>
      </Box>
      {props.showFooter && (
        <>
          <PhantomContainer />
          <FooterContainer>
            <Typography variant="h3" color="Text/Primary Text">
              {`ผลการพิจารณา (${props.claimRequestRange} รายการ)`}
            </Typography>
            <FlexBox mt="16px" mb="8px">
              <RejectActionButton
                variant="outlined"
                color={AppColor["Other/Danger"]}
                style={{
                  borderColor:
                    props.disabledRejectButton || props.claimRequestRange === 0
                      ? AppColor["Text/Gray Success"]
                      : AppColor["Other/Danger"],
                }}
                onClick={props.handleOpenRejectModal}
                mr="16px"
                disabled={props.disabledRejectButton || props.claimRequestRange === 0}
              >
                ตรวจสอบเพิ่มเติม
              </RejectActionButton>
              <ActionButton
                onClick={props.handleOpenApproveModal}
                variant="contained"
                disabled={props.disabledApproveButton || props.claimRequestRange === 0}
              >
                อนุมัติ
              </ActionButton>
            </FlexBox>
          </FooterContainer>
        </>
      )}
    </PageContent>
  </>
)

const API = {
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!, $filters: JSON, $pagination: JSON) {
      claimRequestList(input: { status: $status, filters: $filters, pagination: $pagination }) {
        workflowSeq
        id
        type
        status
        info
        employee
        createdAt
        createdBy
        referenceId
        currentWorkflow
      }
    }
  `,
  APPROVE_ALL_REQUEST: gql`
    mutation APPROVE_ALL_REQUEST($claimLists: [JSON!]!) {
      approveAllClaimRequest(input: { claimLists: $claimLists }) {
        id
        type
        status
        createdAt
      }
    }
  `,
  REJECT_ALL_REQUEST: gql`
    mutation REJECT_ALL_REQUEST($claimLists: [JSON!]!, $rejectReason: JSON, $remark: String) {
      rejectAllClaimRequest(input: { claimLists: $claimLists, rejectReason: $rejectReason, remark: $remark }) {
        id
        type
        status
        createdAt
      }
    }
  `,
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        workflows
      }
    }
  `,
  GET_EMPLOYEE_OPTONS: gql`
    query GET_EMPLOYEE_OPTONS {
      getEmployeeOptions {
        label
        value
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useQuery, useEffect, useCallback, useMemo, useState, useMutation, useUrlParam } = hooks
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const queryParams = useUrlParam()
    const { tab } = queryParams

    const [variables, setVariables] = useState({
      status: EnumClaimRequestStatus.WAITING,
      pagination: { offset: 0, limit: 10 },
    })

    const { data, refetch, fetchMore } = useQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: { status: tab, filters: variables.filters || {}, pagination: { offset: 0, limit: 10 } },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data.claimRequestList.length === 0) {
          setHasMore(false)
        }
      },
    })
    const [approveAllRequest] = useMutation(API.APPROVE_ALL_REQUEST)
    const [rejectAllRequest] = useMutation(API.REJECT_ALL_REQUEST)
    const { data: configs } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS)
    const [canSubmit, setCanSubmit] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const claimRequestRange = data?.claimRequestList?.length || 0
    const [filterCount, setFilterCount] = useState(0)
    const { data: employee, loading: loadingEmployee, error: errorEmployee } = useQuery(API.GET_EMPLOYEE_OPTONS, {
      fetchPolicy: "network-only",
    })

    const employeeOptions = useMemo(() => {
      if (loadingEmployee || errorEmployee) {
        return []
      }
      return employee?.getEmployeeOptions || []
    }, [employee, loadingEmployee, errorEmployee])

    const handleChangeStatus = useCallback(
      (status: EnumClaimRequestStatus) => {
        paths.claimRequestsPath({ tab: status, filters: variables.filters }).push()
        setVariables({ ...variables, status: status })
        setHasMore(false)
      },
      [variables],
    )

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const handleClickHistoryItem = useCallback(
      (id: string) => {
        paths.approvalRequestPath(id, { tab: tab }).push()
      },
      [tab],
    )

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const fetchMoreData = useCallback(() => {
      fetchMore({
        variables: {
          status: tab,
          filters: variables.filters,
          pagination: {
            offset: data?.claimRequestList.length,
            limit: 10,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (fetchMoreResult.claimRequestList) setHasMore(fetchMoreResult.claimRequestList.length > 0)
          if (!fetchMoreResult) return prev
          return {
            claimRequestList: [...prev.claimRequestList, ...fetchMoreResult.claimRequestList],
          }
        },
      })
    }, [fetchMore, variables.filters, data, tab])

    const openFilterModal = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimRequests employeeOptions={employeeOptions} initialValues={variables.filters} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          paths.claimRequestsPath({ tab: tab, filters: values }).push()
          setVariables({ ...variables, filters: values })
          setCanSubmit(true)
        },
      })
    }, [employeeOptions, variables, tab])

    const handleCloseErrorModal = useCallback((confirmProps: any) => {
      confirmProps.close()
      setDisabledApproveButton(false)
      setDisabledRejectButton(false)
    }, [])

    const refetchClaimList = useCallback(async () => {
      await refetch({ ...variables })
      const filterValues = variables.filters
      if (!isEmpty(filterValues)) {
        setHasFilter(true)
        setFilterCount(Object.values(filterValues).filter((item) => !!item).length)
      }
      if (every(filterValues, (value) => !value)) {
        setHasFilter(false)
        setFilterCount(0)
      }
    }, [refetch, variables])

    const errorAlert = useCallback(
      (message: any) => {
        // @ts-ignore
        Modal.alert({
          className: "deleteConFirmModal",
          title: "",
          children: (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box mb="16px">
                <Icon width="40px" height="40px" name="Cancel" color={AppColor["Other/Danger"]} />
              </Box>
              <Box justifyContent="flexStart">
                <Typography variant="h3" color="Text/Black2">
                  ทำรายการไม่สำเร็จ
                </Typography>
              </Box>
              <Box>
                <Box display="flex" justifyContent="flex-start" mt="8px">
                  <Typography variant="h4" color="Text/Gray Preview">
                    {message || ""}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" mt="4px" mb="16px">
                  <Typography variant="body1" color="Text/Gray Preview">
                    * เพื่อลดข้อผิดพลาด กรุณาดำเนินการทีละประเภท
                  </Typography>
                </Box>
              </Box>
            </Box>
          ),
          okButtonLabel: "ตกลง",
          okButtonVariant: "contained",
          buttonWidth: "100%",
          onOk: handleCloseErrorModal,
        })
      },
      [handleCloseErrorModal],
    )

    const handleConfirmApprove = useCallback(async () => {
      try {
        setDisabledApproveButton(true)
        setDisabledRejectButton(true)
        const claimLists = data?.claimRequestList.map((claimRequest: any) => ({
          requestId: claimRequest.id,
          seq: claimRequest.currentWorkflow + 1,
        }))
        await approveAllRequest({ variables: { claimLists } })
        refetchClaimList()
        // @ts-ignore
        Notification.success("บันทึกข้อมูลแล้ว")
        setDisabledApproveButton(false)
        setDisabledRejectButton(false)
      } catch (e: any) {
        if (e) {
          errorAlert(e.message)
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        }
      }
    }, [approveAllRequest, data?.claimRequestList, errorAlert, refetchClaimList])

    const handleOpenApproveModal = useCallback(async () => {
      // @ts-ignore
      Modal.open({
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="CheckCircle" color={AppColor.Success} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                ยอมรับการเบิกสวัสดิการ ?
              </Typography>
            </Box>
            <Box mt="8px">
              <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                อนุมัติการเบิก
              </Typography>
            </Box>
            <Box mt="4px" mb="16px">
              <Typography variant="h5" color={AppColor["Primary/Primary Text"]} isCurrency>
                {`${claimRequestRange} รายการ`}
              </Typography>
            </Box>
          </div>
        ),
        onOk: () => {
          handleConfirmApprove()
          // @ts-ignore
          Modal.close()
        },
        okButtonLabel: "อนุมัติการเบิก",
        okButtonVariant: "contained",
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
      })
    }, [claimRequestRange, handleConfirmApprove])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])

    const handleConfirmReject = useCallback(
      async (rejectReason: any, remark?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          const claimLists = data?.claimRequestList.map((claimRequest: any) => ({
            requestId: claimRequest.id,
            seq: claimRequest.currentWorkflow + 1,
          }))
          await rejectAllRequest({ variables: { claimLists, rejectReason: rejectReason, remark } })
          refetchClaimList()
          // @ts-ignore
          Notification.success("บันทึกข้อมูลแล้ว")
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            errorAlert(e.message)
            setDisabledApproveButton(false)
            setDisabledRejectButton(false)
          }
        }
      },
      [data?.claimRequestList, errorAlert, refetchClaimList, rejectAllRequest],
    )

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimList()
          setCanSubmit(false)
          // @ts-ignore
          Modal.close()
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimList])

    const showNotFoundSearch = useMemo(() => data?.claimRequestList.length === 0 && hasFilter, [data, hasFilter])

    const currentUserLv = useMemo(() => {
      if (currentUser && !isEmpty(currentUser.meta)) {
        if (functions.validate["findEmployeeLv"] === undefined) {
          return "unit_name_lv6"
        }
        return functions.validate["findEmployeeLv"](currentUser)
      }
      return "unit_name_lv6"
    }, [currentUser])

    const showFooter = useMemo(() => {
      if (currentUserLv) return currentUserLv !== "unit_name_lv6" && tab === EnumClaimRequestStatus.WAITING
      return false
    }, [currentUserLv, tab])

    const rejectReasons = useMemo(() => {
      if (configs && currentUserLv) {
        return (
          configs?.requestConfigs.workflows.find((workflow: any) => workflow.custom === currentUserLv)
            ?.rejectedReasons || []
        )
      }
      return []
    }, [configs, currentUserLv])

    useEffect(() => {
      if (data?.claimRequestList.length === 0) {
        setHasMore(false)
      }
    }, [data])

    return {
      handleChangeStatus,
      claimRequestList: data?.claimRequestList || [],
      claimRequestRange,
      requestAmount,
      handleClickHistoryItem,
      filteredStatus: tab,
      handleClickBack,
      currentUser,
      openFilterModal,
      hasFilter,
      showNotFoundSearch,
      fetchMoreData,
      hasMore,
      showFooter,
      handleOpenApproveModal,
      handleOpenRejectModal,
      handleCloseRejectModal,
      handleConfirmReject,
      disabledApproveButton,
      disabledRejectButton,
      isRejectModalOpen,
      rejectReasons,

      filterCount,
    }
  }),
)

const ClaimRequestPage = enhancer(ClaimRequestsPageComponent)

export default ClaimRequestPage
