import { Box, Typography, Icon } from "components/common"
import { compose, withHooks } from "enhancers"
import EditForm from "./editForm"
import { isEmpty } from "lodash"

const TextButtonWithEditModal = (props: any) => (
  <>
    {props.userHaveDependents ? (
      <Box>
        <EditForm
          isOpen={props.isOpen}
          requester={props.requester}
          maxDate={props.maxDate}
          options={props.options}
          claimType={props.claimType}
          allUserDependents={props.allUserDependents}
          onClose={() => props.setIsOpen(false)}
          refetch={props.refetch}
        />
        <Box
          display="flex"
          justifyContent="end"
          alignItems="center"
          onClick={props.openModal}
          style={{ cursor: "pointer" }}
        >
          <Box mr={2}>
            <Typography variant="Body/16" color="#005AE0">
              แก้ไขข้อมูลครอบครัว
            </Typography>
          </Box>
          <Box>
            <Icon name="arrowRight" />
          </Box>
        </Box>
      </Box>
    ) : (
      <></>
    )}
  </>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { requester, maxDate, options, claimType, allUserDependents, refetchDependents } = props
    const { useCallback, useState, useMemo } = hooks

    const [isOpen, setIsOpen] = useState(false)

    const userDependents = useMemo(() => {
      if (isEmpty(allUserDependents)) {
        return []
      }
      return allUserDependents.filter((dependent: any) => dependent.id !== "self")
    }, [allUserDependents])

    const customOptions = useMemo(() => {
      if (isEmpty(options)) {
        return []
      }
      return options.filter((option: any) => option.value !== "self")
    }, [options])

    const openModal = useCallback(() => {
      setIsOpen(true)
    }, [])

    return {
      openModal,
      isOpen,
      setIsOpen,

      maxDate,
      claimType,
      options: customOptions,
      requester,

      userHaveDependents: customOptions.length > 0,
      allUserDependents: userDependents,
      refetch: refetchDependents,
    }
  }),
)

export default enhancer(TextButtonWithEditModal)
