import { compose, withFormik, withHooks } from "enhancers"
import { ApolloError, gql } from "@apollo/client"
import { Box, Button, DatePicker, Field, Form, Grid, Notification, Select, TextField, Typography } from "components"
import { Dialog } from "@material-ui/core"
import { notifyError, Yup } from "utils/helper"
import dayjs from "dayjs"
import { isEmpty } from "lodash"

const CreateForm = (props: any) => (
  <Dialog open={props.isOpen} onClose={props.onCloseModal}>
    <Form>
      <Box>
        <Box display="flex" justifyContent="start" mb={4}>
          <Typography variant="h3" color="Text/Black2">
            เพิ่มข้อมูลครอบครัว
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              ชื่อ - นามสกุล
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field component={TextField} name="fullName" type="text" placeholder="ชื่อ - นามสกุล" fullWidth />
          </Grid>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              ความสัมพันธ์
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="relation"
              type="text"
              placeholder="ความสัมพันธ์"
              options={props.relationOptions}
              fullWidth
            />
          </Grid>
          <Box display="flex" mb={2}>
            <Typography variant="body1" color="Text/Black2">
              วันเดือนปีเกิด
            </Typography>
            <Typography variant="body1" color="Other/Danger">
              *
            </Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              fast={false}
              component={DatePicker}
              name="birthDate"
              placeholder="วันเดือนปีเกิด"
              maxDate={props.maxDate}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box display="flex" flex={1} justifyContent="space-between" alignItems="center" width="100%" mt={4}>
          <Button
            variant="outlined"
            onClick={props.onCloseModal}
            style={{
              marginRight: 16,
              width: 100,
              flex: 1,
            }}
          >
            ปิดหน้าต่างนี้
          </Button>
          <Button variant="contained" type="submit" style={{ width: 100, flex: 1 }}>
            เพิ่มข้อมูล
          </Button>
        </Box>
      </Box>
    </Form>
  </Dialog>
)

const API = {
  CREATE_USER_DEPENDENT: gql`
    mutation CREATE_USER_DEPENDENT(
      $requesterId: String!
      $firstName: String!
      $lastName: String!
      $relation: String!
      $birthDate: String!
    ) {
      createUserDependent(
        input: {
          requesterId: $requesterId
          firstName: $firstName
          lastName: $lastName
          relation: $relation
          birthDate: $birthDate
        }
      ) {
        name
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValue: () => ({
      fullName: "",
      relation: "",
      birthDate: "",
    }),
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
      relation: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
      birthDate: Yup.string().required("ต้องไม่เว้นว่างไว้").nullable(),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { maxDate, claimType, isOpen, onClose, resetForm, setFieldValue, requester, setFieldError, refetch } = props
    const { useMemo, useHandleSubmit, useCallback, useMutation } = hooks

    const [createUserDependent] = useMutation(API.CREATE_USER_DEPENDENT)

    const relationOptions = useMemo(() => {
      switch (claimType) {
        case "ค่ารักษาพยาบาล":
          return [
            { label: "คู่สมรส", value: "spouse" },
            { label: "บุตร", value: "child" },
          ]
        case "ดูแลบุตร":
          return [{ label: "บุตร", value: "child" }]
        default:
          return [
            { label: "บิดา", value: "dad" },
            { label: "มารดา", value: "mom" },
            { label: "คู่สมรส", value: "spouse" },
            { label: "บุตร", value: "child" },
          ]
      }
    }, [claimType])

    const onCloseModal = useCallback(() => {
      onClose()
      resetForm()
    }, [onClose, resetForm])

    useHandleSubmit(async (values: any) => {
      const { fullName, relation, birthDate } = values
      const splitFullName = fullName.replace(/\s+/g, " ").trim().split(" ")
      const firstName = splitFullName[0]
      const lastName = splitFullName.slice(1).join(" ")
      const input = {
        requesterId: requester?.id,
        firstName: firstName,
        lastName: lastName,
        relation: relation,
        birthDate: new Date(birthDate).toISOString(),
      }
      try {
        await createUserDependent({
          variables: { ...input },
        })
        refetch()
        // @ts-ignore
        Notification.success("เพิ่มข้อมูลครอบครัวสำเร็จ")
        onCloseModal()
      } catch (error: any) {
        console.log("error", error?.graphQLErrors[0])
        const errorMessage = JSON.parse(error?.graphQLErrors[0].message)
        const code = errorMessage.code
        if (code === "422-016") {
          const message = "ชื่อและนามสกุลนี้ซ้ำกับในระบบ"
          setFieldError("fullName", message)
        } else {
          notifyError("เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน")
        }
      }
    }, [])

    return {
      relationOptions,
      maxDate,
      setFieldValue,

      onCloseModal,
      isOpen,
    }
  }),
)
export default enhancer(CreateForm)
